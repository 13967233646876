import React from 'react';
import './Summary.css';

const Summary = ({ event }) => {
  return (
    <div>
      <div className="summary-label">{event.locale === 'it_IT' ? 'SOMMARIO' : 'SUMMARY'}</div>
      <p className="summary-text">{event.summary}</p>
      <div className="summary-label-down"></div>
    </div>
  );
}

export default Summary;