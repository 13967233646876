import React from 'react';
import { Grid, Container } from '@material-ui/core/';
import './App.css';
import Title from './components/Title'
import Logo from './components/Logo';
import DateAndTime from './components/DateAndTime'
import Location from './components/Location'
import Summary from './components/Summary'
const QRCode = require('qrcode.react');


function App (eventjson) {
  let event = eventjson.event;
  if (!event) return ('Wait data');
  return (
    < div className="App" >
      <Container className="containerFix">
        <Title titleText="DISCOVER ALL TALENT GARDEN EVENTS HERE"></Title>
      </Container>

      <Container fixed className="containerFix">
        <Grid container spacing={3} className="fixLandscapeGrid">
          <Grid item xs={12} md={12} lg={6}>
            <Logo logoUrl={event.logo_url}></Logo>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DateAndTime event={event}></DateAndTime>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Location event={event}></Location>
          </Grid>
          <Grid item md={12} lg={6}>
            <Summary event={event}></Summary>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <p className="alert-text">SIGN UP NOW!</p>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <QRCode className="qr-code" value={event.url} size={180} bgColor="#000000" fgColor="#FFFFFF"
              imageSettings={{
                src: "https://cdn.talentgarden.com/images/logo/logo_black.png",
                x: null,
                y: null,
                height: 64,
                width: 64,
                excavate: false,
              }} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}


export default App;
