import React from 'react';
import './DateAndTime.css';
const moment = require('moment');



const DateAndTime = ({ event }) => {
  const date = moment(event.start_utc);
  return (
    <div>
      <h5 className="date-time">{event.locale === 'it_IT' ? 'DATA E ORA' : 'DATE AND TIME'}</h5>
      <p className="date-time-text">{date.format('ddd')}<br></br>{date.format('MMMM')} {date.format('D')},<br></br>{date.format('YYYY')},<br></br>{date.format('H')}:{date.format('mm')}h</p>
    </div>
  );
}

export default DateAndTime;
