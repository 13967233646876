import React from 'react';
import './Title.css';

const Title = ({ titleText }) => {
  return (
    <header>
      <p className='title'>
        {titleText}
      </p>
    </header>
  );
}

export default Title;