import React from 'react';
import './Location.css';

const Location = ({ event }) => {
  return (
    <div>
      <h5 className="location">{event.locale === 'it_IT' ? 'LUOGO' : 'LOCATION'}</h5>
      <p className="location-text">{event.online_event ? 'Online Event' : event.organizer_name}</p>
    </div>
  );
}

export default Location;