import React from 'react';
import ReactDOM from 'react-dom'
import { useState, useEffect } from "react";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

let { REACT_APP_API_URL } = process.env
let { REACT_APP_TIMER } = process.env;
//const query = new URLSearchParams(this.props.location.search);

// eslint-disable-next-line
const mapCampus = {
  'BCT1': ['29786148665', '4631949257'],
  'BSC1': ['4575255157', '5822382363'],
  'CPD1': ['18538821664', '15809431745'],
  'DUG1': ['16618205809', '15505572516'],
  'MAM1': ['19340723960', '4631949257'],
  'MIC1': ['21681067485', '3237573476', '5822382363'],
  'MIL1': ['2888489877', '3237573476', '5822382363'],
  'MIM1': ['6312664481', '3237573476', '5822382363'],
  'RMO1': ['3231883926', '4200439687', '5822382363'],
  'TOC1': ['25217794479', '10622524788', '5822382363'],
  'TOG1': ['15482207095', '10622524788', '5822382363'],
  'WIL1': ['18107256709', '16030478315']

}


function Events() {
  const urlParams = new URLSearchParams(window.location.search);
  let campus_code = urlParams.get('campus_code');
  campus_code = campus_code ? campus_code.toUpperCase() : campus_code;
  let getEventsUrl = "";
  if ((campus_code !== null) && (mapCampus[campus_code])) {
    const organizerIds = mapCampus[campus_code]
    let query = "&organizer_id=";
    for (let index = 0; index < organizerIds.length - 1; index++) {
      query = query.concat(organizerIds[index]).concat(",")
    }
    query = query.concat(organizerIds[(organizerIds.length - 1)])

    getEventsUrl = `${REACT_APP_API_URL}/v1/events?sort_by=start_utc&per_page=7&status=live`.concat(query);
  } else {
    getEventsUrl = `${REACT_APP_API_URL}/v1/events?sort_by=start_utc&per_page=7&status=live`
  }
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isVisible, setVisible] = useState(true);
  const [events, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState();

  useEffect(() => {
    fetch(getEventsUrl)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.data);
          setPagination(result.pagination);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [getEventsUrl]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!events) return
      if (count + 1 === events.length) {
        setVisible(false)
        setTimeout(() => setCount(count => count = 0), 1000);
        setTimeout(() => { setVisible(true) }, 1500);
      } else {
        setVisible(false)
        setTimeout(() => setCount(count => count + 1), 1000);
        setTimeout(() => { setVisible(true) }, 1500);

      }
    }, REACT_APP_TIMER);
    return () => clearInterval(interval);
  }, [count, events, pagination]);



  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className={isVisible ? 'fadeIn' : 'fadeOut'} >
        <App event={events[count]} />
      </div>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Events />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
